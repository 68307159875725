import React, { Component } from 'react'
import Nav from './Nav'
import { ReactComponent as Laptop } from '../assets/Laptop.svg';
import { ReactComponent as Blob } from '../assets/Blob.svg';
import { ReactComponent as City } from '../assets/City.svg';


export default class Header extends Component {

  scrollToForm = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth"
    })
  }

  render() {
    return(
      <div className="header__container">
        <div id="particles-js" />
        <div className="header__content-wrapper">
          <div className="header__left-side">
            <div className="header__main-title">
              Local Council Household Pickup Automation Software
            </div>
            <div className="header__subtitle">
              Cloud based enterprise council waste management system.
            </div>
            <div className="header__demo-btn demo-btn--purple" onClick={this.scrollToForm}>Request Demo</div>
          </div>
          <div className="header__right-side">
            <Blob className="header__blob"/>
            <Laptop className="header__laptop"/>
            <City className="header__city"/>
          </div>
        </div>


      </div>
    )
  }
}
