import React, { Component } from 'react'
import {ReactComponent as Canterbury} from '../assets/Canterbury.svg'
import {ReactComponent as Strathfield} from '../assets/Strathfield.svg'
import {ReactComponent as ClothingCleanup} from '../assets/ClothingCleanup.svg'


export default class Clients extends Component {
  render() {
    return(
      <div className="clients">
        <div className="clients__title">Our Clients</div>
        <div className="clients__logo-box">
          <Canterbury className="clients__logo"/>
          <Strathfield className="clients__logo"/>
          <ClothingCleanup className="clients__logo"/>
        </div>
      </div>
    )
  }
}
