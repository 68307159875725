import React, { Component } from 'react'

import { ReactComponent as GreenPie } from '../assets/Green_pie.svg'
import { ReactComponent as YellowPie } from '../assets/Yellow_Pie.svg'
import { ReactComponent as RedPie } from '../assets/Red_pie.svg'
import { ReactComponent as Tick } from '../assets/tick.svg'
import { ReactComponent as LeafLeft } from '../assets/Leaf_Left.svg'
import { ReactComponent as LeafRight } from '../assets/Leaf_Right.svg'
import { ReactComponent as Weight } from '../assets/Weight.svg'
import { ReactComponent as Shopping } from '../assets/Shopping.svg'
import { ReactComponent as House } from '../assets/House.svg'

import Map from '../assets/Map.png'

import posed from 'react-pose';
import Lottie from 'react-lottie';

import doughnutGreen from '../assets/animations/doughnutGreen.json'
import doughnutYellow from '../assets/animations/doughnutYellow.json'
import doughnutRed from '../assets/animations/doughnutRed.json'



export default class Info extends Component {
  constructor(){
    super()
  }

  componentDidMount() {

    // Animate the branches
    let branches = document.querySelectorAll('.features-extras__branch-wrapper')
    branches.forEach(branch => {
      let time = Math.floor(Math.random() * 3.5) + 3
      branch.style.setProperty('--animation-time', time +'s');
    })
  }


  render() {
    let greenGraphOptions = {
          loop: true,
          autoplay: true,
          animationData: doughnutGreen,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }

    let yellowGraphOptions = {
          loop: true,
          autoplay: true,
          animationData: doughnutYellow,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }

    let redGraphOptions = {
          loop: true,
          autoplay: true,
          animationData: doughnutRed,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }

    return(
      <div className="info">
        <div className="info__how-it-works">
          <div className="info__left-side">
            <div className="info__header header--green">
              How Does it Work?
            </div>
            <div className="info__body">
              ZenCollect marks a new wave of council automation — increasing efficiency in waste resource management across the board and decreasing administrative staff workload.
            </div>
          </div>
          <div className="info__right-side">
            <img src={Map} alt=""/>
          </div>
        </div>
        <div className="stats">
          <div className="stats__header header--purple">
            Stats
          </div>
          <div className="stats__wrapper">

            <div className="stats__statistic-section">
              <div className="stats__percentage">
                90%
              </div>
              <div className="stats__chart">
                <Lottie options={greenGraphOptions} />
              </div>
              <div className="stats__description">
                Reduction in household pickup related calls
              </div>
            </div>

            <div className="stats__statistic-section">
              <div className="stats__percentage">
                86%
              </div>
              <div className="stats__chart">
                <Lottie options={yellowGraphOptions} />
              </div>
              <div className="stats__description">
                Reduction in not-left-out bookings
              </div>
            </div>


            <div className="stats__statistic-section">
              <div className="stats__percentage">
                21%
              </div>
              <div className="stats__chart">
                <Lottie options={redGraphOptions} />
              </div>
              <div className="stats__description">
                Reduced Support Costs
              </div>
            </div>
          </div>
        </div>

        <div className="features-extras">
          <div className="features-extras__feature-section">
            <div className="features-extras__header header--green">Features</div>
            <div className="features-extras__feature">
              <Tick /><div className="features-extras__feature-text">Driver route management</div>
            </div>
            <div className="features-extras__feature">
              <Tick /><div className="features-extras__feature-text">Ability to add and remove trucks</div>
            </div>
            <div className="features-extras__feature">
              <Tick /><div className="features-extras__feature-text">Customer booking self management portal</div>
            </div>
            <div className="features-extras__feature">
              <Tick /><div className="features-extras__feature-text">Custom domain name (eg. cleanup.strathfield.gov.au)</div>
            </div>
            <div className="features-extras__feature">
              <Tick /><div className="features-extras__feature-text">Live dwelling updates from rates DB, or Google addresses</div>
            </div>
            <div className="features-extras__feature">
              <Tick /><div className="features-extras__feature-text">SMS notifications to residents — before and after successful pickup</div>
            </div>
            <div className="features-extras__feature">
              <Tick /><div className="features-extras__feature-text">Email alerts</div>
            </div>
            <div className="features-extras__feature">
              <Tick /><div className="features-extras__feature-text">Easy to understand explainer videos for ESL residents.</div>
            </div>

          </div>

          <div className="features-extras__feature-section">
            <div className="features-extras__header header--green">Extras</div>
            <div className="features-extras__extra">
              <Tick /><div className="features-extras__feature-text">Mobile App</div>
            </div>
            <div className="features-extras__extra">
              <Tick /><div className="features-extras__feature-text">Illegal Dumping add-on</div>
            </div>
            <div className="features-extras__extra">
              <Tick /><div className="features-extras__feature-text">Request a new bin - resident system</div>
            </div>
            <div className="features-extras__extra">
              <Tick /><div className="features-extras__feature-text">Strata booking management system</div>
            </div>
          </div>

          <LeafLeft className="features-extras__leaf-left"/>
          <LeafRight className="features-extras__leaf-right"/>
        </div>


        <div className="waste">
          <div className="waste__header header--purple">Waste Stats</div>
          <div className="waste__cards">
            <div className="waste__card">
              <Weight className="waste__card-icon"/>
              <div className="waste__card-description">The average Australian produces 2.7 Tonnes of waste a year.</div>
              <div className="waste__card-quote">- environment.gov.au</div>
            </div>
            <div className="waste__card waste__card--big">
              <Shopping className="waste__card-icon"/>
              <div className="waste__card-description">Only 1% of all goods purchased are still used 6 months later.</div>
              <div className="waste__card-quote">- coolaustralia.org</div>
            </div>
            <div className="waste__card">
              <House className="waste__card-icon"/>
              <div className="waste__card-description">Only 26% of households used a council pickup in 2012.</div>
              <div className="waste__card-quote">- ABS</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
