import React, { Component } from 'react'

export default class Nav extends Component {
  render() {
    return(
      <div className="nav-wrapper">
        <div className="nav-item">
          <a href="#">How does it work?</a>
        </div>
        <div className="nav-item">
          <a href="#">Features</a>
        </div>
        <div className="nav-item demo-btn--clear">
          <a href="#">Request Demo</a>
        </div>
      </div>
    )
  }
}
