import React, { Component } from 'react';
import logo from './logo.svg';
import './styles/App.scss';


import Header from './components/Header'
import Clients from './components/OurClients'
import InfoSection from './components/InfoSection'
import Footer from './components/Footer'

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Clients />
        <InfoSection />
        <Footer />
      </div>
    );
  }
}

export default App;
