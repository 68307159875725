import React, { Component } from 'react'
import { Form, Text } from 'informed';
import axios from 'axios'


export default class Footer extends Component {
  constructor(props) {
    super(props)
  }

  handleClick = () => {
    let formState = this.formApi.getState();
    axios.post('http://localhost:4000/api/test-email', formState)
  }

  setFormApi = (formApi) => {
    this.formApi = formApi;
  }

  render() {
    return(
      <div className="footer">
        <div className="footer__form-container">
          <div className="footer__title">
            Get A Demo
          </div>
          <div className="footer__form">
            <Form getApi={this.setFormApi} className="footer_form">
              <Text field="name" className="footer__form-field" placeholder="Your Name"/>
              <Text field="email" className="footer__form-field" placeholder="email address"/>
              <Text field="council" className="footer__form-field" placeholder="Council"/>
              <Text field="phone" className="footer__form-field" placeholder="Phone Number" />
            </Form>
            <button onClick={this.handleClick} className="footer__btn demo-btn--green">Request Demo</button>
          </div>
        </div>
        <div className="footer__end">
          <div className="footer__end-link">&copy; {new Date().getFullYear()}</div>
          <div className="footer__end-link">Privacy Policy</div>
          <div className="footer__end-link">Terms and Conditions</div>
        </div>
      </div>
    )
  }
}
